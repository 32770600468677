/*------MainContent----------*/
.home-page {
  /* 初始设置为横屏 */
  --photo-size: 50vh;
  --photo-border: 61% 29% 63% 37% / 26% 25% 74% 78%;
  --border-size: 0.8rem;
  --container-height: calc(var(--photo-size) + 2 * var(--border-size));
  --container-width: calc(
    calc(2 * (var(--photo-size) + var(--border-size)) + 10vw + 5rem)
  );
}

/* parent: app-content ->position:absolute; */
.home-page {
  display: flex;
  justify-content: center;
  position: relative;
  /* 高度 = 照片大小 + 上下边框 */
  /* height: var(--container-height);
  width: var(--container-width); */
  width: 90vw;
  background-color: transparent;
  gap: 2rem;
  margin-top: 2rem;
  /* border: solid 1px black; */
}
/*------MainContent----------*/

/*------Second Layer---------*/
.intro-content {
  flex: 1;
  /* order: 1; */
  /* position: absolute;
  top: 50%;
  transform: translateY(-50%); */
  /* 照片宽度+照片边框宽度+间隔距离 */
  right: calc(var(--photo-size) + 2 * var(--border-size) + 2rem);
  /* width: calc(var(--photo-size) + 10vw); */
  margin: auto;
  /* border: solid 1px black; */
}

.photo-content {
  border: solid var(--border-size) var(--fifth-theme-color);
  border-radius: var(--photo-border);
  background-image: url("../../../public/zixin.png");
  background-size: cover;
  background-repeat: no-repeat;
  /* position: absolute;
  top: 0;
  right: 0; */
  height: var(--photo-size);
  width: var(--photo-size);
}
/*------Second Layer---------*/

/*------IntroContent----------*/
.intro-content > * {
  margin: 0;
  color: var(--eight-theme-color);
}

.intro-content > p:nth-of-type(1) {
  /* background-color: red; */
}
.intro-content > p:nth-of-type(2) {
  /* background-color: blue; */
  font-weight: bolder;
  font-size: 4rem;
  color: var(--fifth-theme-color);
}
.intro-content > p:nth-of-type(3) {
  /* background-color: green; */
  font-size: 1rem;
}
.intro-content > p:nth-of-type(3) > span:nth-of-type(1) {
  font-size: 1.3em;
  color: var(--sixth-theme-color);
}

.intro-content > p:nth-of-type(3) > span:nth-of-type(2) {
  font-size: 1em;
}

.intro-content > *:nth-child(4) {
  border: 0.2px var(--sixth-theme-color) solid;
  height: 0;
  margin: 1.5rem 0 1rem 0;
}

.intro-content > .contact {
  display: flex;
  justify-content: flex-start;
  gap: 3em;
  align-items: center;
  /* padding-left: 0.5rem; */

  /* border: solid blue 3px; */
}

.intro-content > .contact > * {
  font-size: 1.5rem;
  text-decoration: none;
  color: var(--sixth-theme-color);
}

.intro-content > .contact > *:hover {
  transform: scale(1.1) rotateZ(3deg);
  /* color: var(--fifth-theme-color); */
  transition: all 0.5s;
}
/*------IntroContent----------*/

/*--------------------------------Responsive Deisig--------------------------------*/
/* Styles for potrait mobile devices 竖屏*/
@media screen and (min-width: 0px) and (max-width: 576px) {
  .home-page {
    --photo-size: 50vw;
    --photo-border: 50%;
    --container-width: 100vw;
    --container-height: auto;

    /* 竖式视图 */
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3rem;
    padding-top: 1rem;
  }

  /* 容器absolute -> static */
  .home-page > * {
    position: static;
  }

  /* 文字宽度，顺序 */
  .intro-content {
    transform: initial;
    width: 80%;
    order: 2;
  }

  /* 文字居中 */
  .intro-content > p {
    text-align: center;
  }

  /* 名字大小 */
  .intro-content > p:nth-of-type(2) {
    font-size: 4rem;
  }

  /* contact图标大小 */
  .intro-content > .contact {
    justify-content: space-evenly;
    font-size: 3rem;
  }
}

/* Styles for landscape mobile devices 横屏*/
@media screen and (min-width: 577px) and (max-width: 767px) {
  .intro-content > p:nth-of-type(2) {
    font-size: 3rem;
  }
}

/* Styles for ipad devices */
@media screen and (min-width: 768px) and (max-width: 992px) {
  .home-page {
    --photo-size: 40vh;
    --photo-border: 50%;
    --container-width: 100vw;
    --container-height: auto;

    /* 竖式视图 */
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3rem;
    padding-top: 1rem;
  }

  /* 容器absolute -> static */
  .home-page > * {
    position: static;
  }

  /* 文字宽度，顺序 */
  .intro-content {
    transform: initial;
    width: 80%;
    order: 2;
  }

  /* 文字居中 */
  .intro-content > p {
    text-align: center;
  }

  /* 名字大小 */
  .intro-content > p:nth-of-type(2) {
    font-size: 4rem;
  }

  /* contact图标大小 */
  .intro-content > .contact {
    justify-content: space-evenly;
    font-size: 3rem;
  }
}

/* Styles for PC devices */
@media screen and (min-width: 993px) and (max-width: 1200px) {
  .home-page {
    --photo-size: 40vw;
  }
}

/* Styles for Large PC devices */
@media screen and (min-width: 1201px) {
  .home-page {
    --photo-size: 35vw;
  }
}
/*--------------------------------Responsive Deisig--------------------------------*/
