/*-----------Common-------------*/
:root {
  /* --list-normal-width: 150px;
  --list-normal-height: 200px; */
}

.extend-nav {
  --extend-list-height: calc(var(--extend-height) * 0.9);
  --extend-game-img-height: calc(var(--extend-height) * 0.65);
  --extend-game-img-width: calc(var(--extend-height) * 0.65 / 11 * 10);
  --cur-position: 0;
}

.extend-nav {
  position: absolute;
  background-color: var(--fifth-theme-color);
  top: calc(var(--nav-height) - var(--extend-height));
  box-shadow: rgba(0, 0, 0, 0.1) -4px 9px 25px -6px;
  width: 100vw;
  display: flex;
  height: var(--extend-height);

  z-index: 1;
}

.extend-nav.vertical {
  --extend-height: calc(100vh - var(--nav-height));
  /* height: calc(100vh - var(--nav-height));  */
  flex-direction: column;
}

.extend-nav.horizontal {
  --extend-height: 350px;
  /* flex-direction: row; */
}
/*--------Common-------------*/

/*-----------------Animation-----------------*/
@keyframes open-extend {
  from {
    /* top: calc(var(--nav-height) - var(--extend-height)); */
    top: var(--current-top);
    padding-top: 0;
    /* display: none; */
  }
  to {
    top: var(--nav-height);
    padding-top: var(--nav-height);
    /* display: flex; */
  }
}

@keyframes close-extend {
  from {
    /* top: var(--nav-height); */
    top: var(--current-top);
    padding-top: var(--nav-height);
  }
  to {
    top: calc(var(--nav-height) - var(--extend-height));
    padding-top: 0;
  }
}

/*-----------------Animation-----------------*/

/*--------------------------- Button (Vertical)-----------------------------------*/
.extend-nav.vertical > button {
  padding: 1rem 1.5rem;
  text-align: left;
  font-size: 1.5rem;
  color: black;
  font-weight: bold;

  transition: all 0.2s ease-in-out;
}

.extend-nav.vertical > button:focus {
  background-color: aliceblue;
}
/*--------------------------- Button (Vertical)-----------------------------------*/

.extend-content,
.extend-profile {
  display: flex;
  flex-direction: row;
  /* align-items: center; */
  /* width: 100vw; */
  /* height: var(--extend-height); */
  height: auto;
  gap: 2rem;
  margin: 1rem 2rem;
}

.list-name {
  font-size: 1rem;
  text-align: center;
  margin-top: 0.4rem;
  /* background-color: aquamarine; */
}

.list .normal {
  /* width: var(--list-normal-width); */
}
/*-----------Common-------------*/

/*------GameExtendBar----------*/
#game-extend-bar {
  justify-content: flex-start;
  transform-style: preserve-3d;
  perspective: 5000px;
}

#game-extend-bar #game-li {
  height: fit-content;
  width: auto;
  background-color: var(--primary-theme-color);
  padding: 0.8rem 0.2rem;
  border-radius: 3%;

  transition: box-shadow 0.4s ease-in-out, transform 0.4s ease-in-out;
  animation: show-extend-content 1s ease-in-out forwards;
}

#game-extend-bar #game-li:hover {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 4px,
    rgba(0, 0, 0, 0.2) 0px 7px 13px -3px, rgba(0, 0, 0, 0.1) 0px -3px 0px inset;
  transform: translateZ(1rem);
  -webkit-transform: translateZ(1rem);
  cursor: pointer;
}

.game-img {
  height: var(--extend-game-img-height);
  width: var(--extend-game-img-width);

  background-size: 100% auto;
  background-repeat: no-repeat;
}
/*------GameExtendBar----------*/

/*------ProfileExtendBar----------*/
#profile {
  justify-content: flex-end;
}

#profile .user-headshot {
  background: linear-gradient(180deg, pink, pink 50%, white 50%, white);
  display: flex;
  justify-content: center;
}

#profile .user-info {
  background-color: rgb(228, 143, 143);
}
#profile .user-info * {
  padding: 1rem;
}

#profile img {
  padding: 3rem 4rem;
  height: 100px;
  width: 100px;
  border-radius: 50%;
  align-items: center;
}

#profile .function-list {
  /* width: var(--list-normal-width); */
  justify-content: start;
}

#profile button {
  padding: 1rem 1.5rem;
  transition: background-color 0.5s ease-in-out, color 0.5s ease-in-out;
}

#profile button:hover {
  background-color: rgb(228, 143, 143);
  color: rgb(55, 11, 11);
}

/*------ProfileExtendBar----------*/
