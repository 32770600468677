nav {
  --nav-img: calc(var(--nav-height) * 0.7);
  --extend-height: 350px;
  /* --extend-list-height: calc(var(--extend-height) * 0.9);
  --extend-game-img-height: calc(var(--extend-height) * 0.65);
  --extend-game-img-width: calc(var(--extend-height) * 0.65 / 11 * 10); */
}
/*------NavigationBar----------*/
nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: var(--nav-height);
  background-color: var(--fifth-theme-color);

  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;

  z-index: 999;
}

/*------NavigationBar----------*/

nav .left,
nav .right {
  height: var(--nav-height);
  display: flex;

  z-index: 3;
}
/* 
nav .right {
  justify-content: flex-end;
} */

nav button{
  position: relative;

  background-color: var(--fifth-theme-color);
  height: var(--nav-height);

  font-size: 1rem;
  padding: 0 1.5rem;
  color: white;
  /* border: solid 2px paleturquoise; */
}

nav .headshot {
  height: var(--nav-img);
  width: var(--nav-img);
  border-radius: 50%;
}

/*--------------------------- Button (Only for Vertical)-----------------------------------*/
#menu {
  color: var(--main-icon-color);
}

#menu > * {
  width: 2rem;
  height: 4px;
  background-color: #333;
  margin: 3px 0;
  transition: 0.4s;
}

.menu.change .bar1 {
  transform: translate(0, 7px) rotate(-45deg);
  -webkit-transform: translate(0, 7px) rotate(-45deg);
}

.menu.change .bar2 {
  opacity: 0;
}

.menu.change .bar3 {
  transform: translate(0, -7px) rotate(45deg);
}
/*--------------------------- Button (Only for Vertical)-----------------------------------*/
/* -------------------------- Button Animation (Underline)-------------------------- */
#nav > * > button::before {
  content: "";
  background-color: var(--fourth-theme-color);
  position: absolute;

  left: 50%;
  bottom: 0;
  width: 0;
  height: 2px;

  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  transition: width 0.4s ease;
}

#nav > * > button:hover::before {
  width: 100%;
}
/* -------------------------- Button Animation -------------------------- */

/*--------------------------------Responsive Design--------------------------------*/
/* Styles for potrait mobile devices */
@media screen and (min-width: 0px) and (max-width: 576px) {
  #vertical {
    display: block;
  }


}

/* Styles for landscape mobile devices */
@media screen and (min-width: 577px) and (max-width: 768px) {
  #vertical {
    display: block;
  }
}

/* Styles for ipad devices */
@media screen and (min-width: 769px) and (max-width: 992px) {
  #vertical {
    display: none;
  }
}

/* Styles for PC devices */
@media screen and (min-width: 993px) and (max-width: 1200px) {
  #vertical {
    display: none;
  }
}

/* Styles for Large PC devices */
@media screen and (min-width: 1201px) {
  #vertical {
    display: none;
  }
}
/*--------------------------------Responsive Deisig--------------------------------*/
