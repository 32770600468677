:root {
  --wordle-primary-color: white;
  --wordle-secondary-color: rgb(232, 232, 232);
  --wordle-third-color: rgb(113, 211, 253);
  --wordle-green: rgb(198, 231, 90);
  --wordle-yellow: rgb(243, 216, 65);
  --wordle-grey: rgb(171, 171, 171);
}

#wordle {
  width: 100vw;
  min-height: calc(100vh - var(--nav-height));
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* open summary button */
#wordle .fa.fa-chart-bar {
  position: absolute;
  right: 0;
  top: 0;
  margin: 2rem;
  height: 3rem;
  width: 3rem;
  border-radius: 50%;
  background-color: var(--wordle-primary-color);
  color: var(--wordle-secondary-color);
  font-size: 1.5rem;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 1px 2px 0px;
  transition: all 0.3s ease-in-out;
}

#wordle .fa.fa-chart-bar:hover {
  color: rgb(185, 185, 185);
  transform: scale(1.05);
  box-shadow: rgba(0, 0, 0, 0) 0px 1px 2px 0px;
}
/* ---------------------------Input------------------------- */
#wordle > .input {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.3rem;
  padding: 5vh 0 0 0;
}

#wordle > .input > .words {
  display: flex;
  flex-direction: row;
  align-items: center;

  /* 每行words的间距 */
  gap: 0.3rem;
}

#wordle > .input > .words > .word {
  border: solid 0.1rem var(--wordle-secondary-color);
  background-color: var(--wordle-primary-theme-color);

  display: flex;
  justify-content: center;
  align-items: center;

  will-change: transform;
}

#wordle > .input > .words > .word > .letter-text {
  font-weight: bolder;
  color: black;
}
/* ---------------------------Input------------------------- */

/*----------------------Keyboard-----------------------------*/
#wordle .keyboard {
  /* min-height: calc((100vh - var(--nav-height)) * 1 / 3); */
  max-width: 100vw;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  gap: 0.5rem;
  padding: 5vh 0 0 0;
}

#wordle .keyboard-row {
  padding: 0 1rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;

  gap: 0.5rem;
}

.keyboard-button {
  background-color: var(--wordle-secondary-color);

  font-weight: bolder;
  border-radius: 3px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.keyboard-button:hover {
  /* background-color: var(--wordle-third-color); */
  filter: brightness(0.9);
}

.keyboard-button > p {
  min-width: 1rem;
  font-size: 2rem;
  pointer-events: none;
  color: black;
}

.backspace,
.enter {
  width: fit-content;
}

.backspace > p,
.enter > p {
  min-width: fit-content;
}
/*----------------------Keyboard-----------------------------*/

/*--------------------Animation for Input--------------------*/
.gn {
  background-color: var(--wordle-green);
  border-color: var(--wordle-green);
}

.gy {
  background-color: var(--wordle-grey);
  border-color: var(--wordle-grey);
}

.yw {
  background-color: var(--wordle-yellow);
  border-color: var(--wordle-yellow);
}

@keyframes reverse-board-gn {
  from {
    transform: rotate(0);
    -webkit-transform: rotateY(0);
  }
  to {
    transform: rotateY(180deg);
    -webkit-transform: rotateY(180deg);
    background-color: var(--wordle-green);
    border-color: var(--wordle-green);
  }
}

@keyframes reverse-board-gy {
  from {
    transform: rotate(0);
    -webkit-transform: rotateY(0);
  }
  to {
    transform: rotateY(180deg);
    -webkit-transform: rotateY(180deg);
    background-color: var(--wordle-grey);
    border-color: var(--wordle-grey);
  }
}

@keyframes reverse-board-yw {
  from {
    transform: rotate(0);
    -webkit-transform: rotateY(0);
  }
  to {
    transform: rotateY(180deg);
    -webkit-transform: rotateY(180deg);
    background-color: var(--wordle-yellow);
    border-color: var(--wordle-yellow);
  }
}

@keyframes reverse-letter {
  from {
    transform: rotate(0);
    /* -webkit-transform: rotateY(0) ; */
    color: black;
  }
  to {
    transform: rotateY(180deg);
    /* -webkit-transform: rotateY(180deg) ; */
    color: white;
  }
}
/*--------------------Animation for Input--------------------*/

/*-------------------------Summary---------------------------*/
@keyframes fadeInUp {
  0% {
    left: -100%;
    /* opacity: 0; */
  }
  100% {
    left: 0;
    /* opacity: 1; */
  }
}

@keyframes fadeOutDown {
  0% {
    left: 0%;
  }
  100% {
    left: 100%;
  }
}

#wordle .summary-container {
  position: absolute;
  background-color: var(--wordle-primary-color);
  /* border: solid 1px black; */
  left: -100%;

  height: calc(100% - var(--nav-height));
  width: 100%;

  padding: 4rem 0;

  /* opacity: 0; */
  animation: fadeInUp 1s ease-in forwards 1s;

  /* display: flex;
  justify-content: center;
  align-items: center; */
}
#wordle .summary-container > * {
  flex: 1;
}
#wordle .summary-container > .real-space {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  /* border: solid 1px blue; */
  width: 33%;
  height: auto;
  /* height: calc(100% - var(--nav-height)); */

  display: flex;
  flex-direction: column;
  justify-content: center;
}
/*summary的每一行*/
#wordle .summary-container > .real-space > * {
  /* border: solid 1px blue; */
  margin: 1.5rem 0 0 0;
}
/* #wordle .summary-container > .real-space > * {
  border: solid 1px red;
} */
#wordle .summary-container > .real-space > * > .title {
  /* color: blue;
  border: solid 1px blue;
  text-align: center; */
}

#wordle #close-wordle-summary {
  position: absolute;
  top: -1.5em;
  right: -1.5em;
  /* border: solid 1px yellow; */
  width: fit-content;
  /* height: calc(100% - var(--nav-height)); */
  /* transform: scale(0.8); */
}

#wordle .summary-container > * > .title {
  text-align: center;
  font-size: 2.5rem;
}

/* #wordle .summary-container .stats-summary {
  display: flex;
  flex-direction: row;
} */

#wordle .summary-container .stats-content {
  display: flex;
  flex-direction: row;
}

#wordle .stats-content > * {
  display: flex;
  flex-direction: column;
  flex: 1;
}

#wordle .subtitle {
  text-align: center;
  font-size: 0.8rem;
  text-wrap: nowrap;
}

#wordle .stats {
  text-align: center;
  font-size: 2.5rem;
  font-weight: bolder;
}

#wordle .histogram {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
}

#wordle .histogram-row {
  display: flex;
  flex-direction: row;
  align-items: center;

  padding-left: 1rem;
}

#wordle .histogram-row > .title {
  /* border: solid red 1px; */
  width: 1rem;
  text-align: center;
  font-size: 1.5rem;
  font-weight: bolder;
}

.bar {
  bottom: 0;
  width: 200px;
  height: 1.5rem;
  margin-left: 0.5rem;
}

.percent {
  position: relative;
  width: 0px;
  height: 1.5rem;
  background-color: var(--wordle-grey);

  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.percent.current {
  background-color: var(--wordle-green);
}

.percent .win-times {
  font-size: 0.8rem;
  color: white;
  font-weight: bolder;
  margin-right: 0.2rem;
}

@keyframes progress-change {
  0% {
    width: 0;
  }
  100% {
    width: var(--progress-percent);
  }
}

/*-------------------------Summary---------------------------*/

/*--------------------------------Responsive Design--------------------------------*/
/* Styles for potrait mobile devices 竖屏*/
@media screen and (min-width: 0px) and (max-width: 576px) {
  #wordle > .input > .words > .word {
    height: 4rem;
    width: 4rem;
  }
  #wordle > .input > .words > .word > .letter-text {
    font-size: 3rem;
  }
  .keyboard-row {
    height: 5rem;
  }
  .keyboard-button {
    width: 8vw;
  }
  .keyboard-button p {
    font-size: 4rem;
  }
  .keyboard-button.enter,
  .keyboard-button.backspace {
    width: 15vw;
  }
  .keyboard-button > p {
    font-size: 1.5rem;
  }
  .keyboard-button.enter > p {
    font-size: 1rem;
  }
  /*-----Summary--------*/
  #wordle .summary-container > .real-space {
    width: 80%;
  }
}

/* Styles for landscape mobile devices 横屏*/
@media screen and (min-width: 577px) and (max-width: 767px) {
  #wordle > .input > .words > .word {
    height: 4rem;
    width: 4rem;
  }
  #wordle > .input > .words > .word > .letter-text {
    font-size: 3rem;
  }
  .keyboard-row {
    height: 4rem;
  }
  .keyboard-button {
    width: 5vw;
  }
  .keyboard-button.enter,
  .keyboard-button.backspace {
    width: 15vw;
  }
  .keyboard-button > p {
    font-size: 1.5rem;
  }
  .keyboard-button.enter > p {
    font-size: 1rem;
  }
  /*-----Summary--------*/
  #wordle .summary-container > .real-space {
    width: 60%;
  }
}

/* Styles for ipad devices */
@media screen and (min-width: 768px) and (max-width: 992px) {
  #wordle > .input > .words > .word {
    height: 6rem;
    width: 6rem;
  }
  #wordle > .input > .words > .word > .letter-text {
    font-size: 4rem;
  }
  .keyboard-row {
    height: 6rem;
  }
  .keyboard-button {
    width: 8vw;
  }
  .keyboard-button.enter,
  .keyboard-button.backspace {
    width: 12vw;
  }
  .keyboard-button > p {
    font-size: 1.5rem;
  }
  .keyboard-button.enter > p {
    font-size: 1rem;
  }

  /* summary */
  #wordle .summary-container > .real-space {
    /* border: solid 1px red; */
    width: 60%;
  }
}

/* Styles for PC devices */
@media screen and (min-width: 993px) and (max-width: 1200px) {
  #wordle > .input > .words > .word {
    height: 5rem;
    width: 5rem;
  }
  #wordle > .input > .words > .word > .letter-text {
    font-size: 4rem;
  }
  .keyboard-row {
    height: 5rem;
  }
  .keyboard-button {
    width: 6vw;
  }

  .keyboard-button.enter,
  .keyboard-button.backspace {
    width: 8vw;
  }
  .keyboard-button > p {
    font-size: 2rem;
  }
  .keyboard-button.enter > p {
    font-size: 1rem;
  }
  /*-----Summary--------*/
  #wordle .summary-container > .real-space {
    width: 60%;
  }
}

/* Styles for Large PC devices */
@media screen and (min-width: 1201px) {
  #wordle > .input > .words > .word {
    height: 3rem;
    width: 3rem;
  }
  #wordle > .input > .words > .word > .letter-text {
    font-size: 2rem;
  }
  .keyboard-row {
    height: 3rem;
  }
  .keyboard-button {
    width: 3rem;
  }

  .keyboard-button.enter,
  .keyboard-button.backspace {
    width: 8vw;
  }
  .keyboard-button > p {
    font-size: 1.5rem;
  }
  .keyboard-button.enter > p {
    font-size: 1rem;
  }
}
/*--------------------------------Responsive Design--------------------------------*/
