* {
  font-family: sans-serif;
  font-size: var(--font-size);
}

body {
  width: 100%;
  margin: 0;
}
#root {
  min-height: 100vh;
  min-width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
:root {
  --primary-theme-color: white;
  --second-theme-color: black;
  --third-theme-color: #b5d1dd;
  --fourth-theme-color: #b3e3db;
  --fifth-theme-color: #8b836f;
  --sixth-theme-color: #547a78;
  --seven-theme-color: #e6c3aa;
  --eight-theme-color: #58381e;

  --nav-height: 64px;

  /*-------- navigation --------*/
  --nav-color-light: rgba(225, 255, 151, 1);
  /*-------- navigation --------*/

  /*-------- extend-navi --------*/
  --extend-list-bg: rgb(234, 246, 211);
  --box-shadow: rgba(53, 128, 0, 0.5);
  --extend-line: rgba(157, 191, 7, 0.8);
  /* extend-navi */

  /*-------- main background --------*/
  --game-bg: ;
  --intro-bg: ;
  --message-bg: ;
  --profile-bg: ;
  /*-------- main background --------*/

  /*-------- container color --------*/
  --container-dark: rgb(92, 108, 46);
  --container-normal: rgb(71, 89, 22);
  --container-light: white;
  --main-container: rgb(255, 255, 255, 0.5);
  /*-------- container color --------*/

  /*-----inputbox----*/
  --inputbox1: rgb(196, 196, 196);
  --inputbox2: rgb(214, 240, 143);
  --inputbox3: rgb(191, 223, 105);
  /*-----inputbox----*/
  /*-------- text color --------*/
  --text-dark: rgb(0, 0, 0);
  --text-normal: rgb(30, 30, 30);
  --text-light: rgb(95, 95, 95);
  --text-warning: rgb(218, 43, 43);
  --text-hyper: blue;
  /*-------- text color --------*/
  /*-------- icon color --------*/
  --grey-icon: rgb(87, 85, 85);
  --grey-icon-hover: rgb(77, 175, 2);
  --other-icon: ;
  --other-icon-hover: ;
  /*-------- text color --------*/
}

#body {
  height: 100vh;
}
button {
  background-color: transparent;
  text-align: center;
  border: none;
}

button:hover {
  cursor: pointer;
}

#app-container {
  position: absolute;
  background-color: var(--fourth-theme-color);
  top: var(--nav-height);
  transition: 0.3s ease-in-out;
  width: 100vw;
  min-height: calc(100vh - var(--nav-height));
  flex: 1;

  /* 确保内容在正中间 */
  display: flex;
  align-items: center;
  justify-content: center;
}
/*------default------*/

/* #main-container {
  width: 100%;
  min-height: 100vh;
  position: absolute;
  top: var(--nav-height);
} */

/*--------------------------------Responsive Deisig--------------------------------*/
/* Styles for potrait mobile devices */
@media screen and (min-width: 0px) and (max-width: 576px) {
  :root {
    --font-size: 12px;
  }
}

/* Styles for landscape mobile devices */
@media screen and (min-width: 577px) and (max-width: 768px) {
  :root {
    --font-size: 12px;
  }
}

/* Styles for ipad devices */
@media screen and (min-width: 769px) and (max-width: 992px) {
  :root {
    --font-size: 14px;
  }
}

/* Styles for PC devices */
@media screen and (min-width: 993px) and (max-width: 1200px) {
  :root {
    --font-size: 1.3vw;
  }
}

/* Styles for Large PC devices */
@media screen and (min-width: 1201px) {
  :root {
    --font-size: 1.5vw;
  }
}
/*--------------------------------Responsive Deisig--------------------------------*/
