.test {
  position: absolute;
  top: var(--nav-height);
  left: 0px;
  font-size: 30px;
  border: solid black 3px;
  z-index: 3;
}
/*------IntroContent----------*/
#introduction {
  position: absolute;
  top: 0;
  left: 0;
  height: auto;
  width: 100%;
}

.left-navigation {
  position: relative;
  top: calc(100vh * 0.2);
  height: calc(100vh * 0.6);
  background: linear-gradient(90deg, rgb(59, 67, 54), rgb(0, 0, 0));
  width: 16px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  z-index: 2;
  position: fixed;
  transition: width 0.5s ease-in-out, border-top-right-radius 0.5s ease-in-out,
    border-bottom-right-radius 0.5s ease-in-out;
}
.left-navigation:hover {
  width: 100px;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
}

#img1 {
  top: 0;
  background: linear-gradient(
      217deg,
      rgb(0, 255, 187),
      rgba(255, 0, 0, 0) 70.71%
    ),
    linear-gradient(280deg, rgb(1, 21, 173), rgba(0, 255, 0, 0) 70.71%),
    linear-gradient(127deg, rgb(157, 255, 0), rgba(0, 255, 0, 0) 70.71%),
    linear-gradient(70deg, rgb(252, 0, 63), rgba(0, 0, 255, 0) 70.71%);
  /* background: url("https://images.unsplash.com/photo-1447966058635-afc12501f7b3?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2225&q=80"); */
}

#img2 {
  top: 100vh;
  background: url(../../../public/photo-background.jpeg);
}

#img3 {
  top: 200vh;
  background: url(../../../public/crochet-background.jpg);
}

#img4 {
  top: 300vh;
  background: url(../../../public/photo-background.jpeg);
}

#img5 {
  top: 400vh;
  background: url("https://images.unsplash.com/photo-1532501854787-31ce43c2b281?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80");
}

#img1,
#img2,
#img3,
#img4,
#img5 {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;
}

#introduction section {
  /*section here is every img like project crochet....*/
  height: 100vh;
  width: 100%;
  overflow: hidden;
  position: absolute;
  left: 0;
}
/*------IntroContent----------*/

/*--------Projects-----------*/
#img1 {
  perspective: 1500px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 0;
}  

#img1 .left-section {
  display: flex;
  width: 50%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#img1 .content {

  height: 40px;
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: -30px;
}
#img1 .content p {
  font-size: 90px;
  font-weight: bolder;
  color: rgba(34, 33, 46, 0.847);
}

#img1 .main-content {
  position: relative;
  left: -50px;
  top: 60px;
  width: 50vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  transform-style: preserve-3d;
  perspective: 1500px;
  transform: rotateX(-0deg) rotateY(-0deg) scale3d(0.72, 0.63, 0.6);
}

#img1 .front {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 100px;
  height: 0;
  width: 0;
  transform-origin: center;
  transform: rotateY(22.5deg) translateZ(411px); 
  transform-style: preserve-3d;
  animation: front-spin 80s linear infinite;
}

#img1 .end {
  height: 0;
  width: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 100px;
  transform-style: preserve-3d;
  transform-origin: center;
  transform: rotateY(202.5deg) translateZ(411px); 
  animation: end-spin 80s linear infinite;
}

.front .project * {
  animation: front-blur 80s linear infinite;
}

.end .project * {
  animation: end-blur 80s linear infinite;
}
@keyframes front-blur {
  0% {
    filter: opacity(1);
  }
  25% {
    filter: opacity(1);
  }
  50% {
    filter: opacity(0.3);
  }
  75% {
    filter: opacity(1);
  }
  100% {
    filter: opacity(1);
  }
}
@keyframes end-blur {
  0% {
    filter: opacity(0.3);
  }
  20% {
    filter: opacity(1);
  }
  30% {
    filter: opacity(1);
  }
  90% {
    filter: opacity(1);
  }
  100% {
    filter: opacity(0.3);
  }
}

@keyframes front-spin {
  0% {
    transform: rotateY(22.5deg) translateZ(411px);
  }
  50% {
    transform: rotateY(202.5deg) translateZ(411px);
  }
  100% {
    transform: rotateY(382.5deg) translateZ(411px);
  }
}
@keyframes end-spin {
  0% {
    transform: rotateY(202.5deg) translateZ(411px);
  }
  50% {
    transform: rotateY(382.5deg) translateZ(411px);
  }
  100% {
    transform: rotateY(562.5deg) translateZ(411px);
  }
}
.project {
  height: auto;
  width: 200px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.project-photo {
  height: 240px;
  width: 180px;
  border-radius: 10px;
  background-color: rgba(58, 65, 127, 1);
  box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px,
    rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px,
    rgba(0, 0, 0, 0.09) 0px 32px 16px;
}

.project-photo:hover {
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}
.project-name {
  text-align: center;
}

#proj1 {
  transform-origin: right top;
  transform: translateZ(-176.5px) translateX(16px) rotateY(-67.5deg)
    rotateX(8deg);
}
#proj2 {
  transform-origin: right top;
  transform: rotateY(-22.5deg) rotateX(8deg);
}
#proj3 {
  transform-origin: left top;
  transform: rotateY(22.5deg) rotateX(8deg);
}
#proj4 {
  transform-origin: left top;
  transform: translateZ(-176.5px) translateX(-16px) rotateY(67.5deg)
    rotateX(8deg);
}

#proj5 {
  transform-origin: right top;
  transform: translateZ(-176.5px) translateX(16px) rotateY(-67.5deg)
    translateZ(0px) rotateX(8deg);
}
#proj6 {
  transform-origin: right top;
  transform: rotateY(-22.5deg) rotateX(8deg);
}
#proj7 {
  transform-origin: left top;
  transform: rotateY(22.5deg) translateZ(0px) rotateX(8deg);
}
#proj8 {
  transform-origin: left top;
  transform: translateZ(-176.5px) translateX(-16px) rotateY(67.5deg)
    translateZ(0px) rotateX(8deg);
}

.project-introduction {
  position: relative;
  top: 2%;
  height: 400px;
  width: 600px;
  background-color: rgba(55, 55, 55, 0.401);
  border-radius: 10px;
  box-shadow: 0 2px 1px rgba(0, 0, 0, 0.09), 0 4px 2px rgba(0, 0, 0, 0.09),
    0 8px 4px rgba(0, 0, 0, 0.09), 0 16px 8px rgba(0, 0, 0, 0.09),
    0 32px 16px rgba(0, 0, 0, 0.09);
  overflow: scroll;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
  border-inline: 1rem solid rgba(0, 0, 0, 0.593);
  animation: introduction-appear 0.4s ease-in forwards;
}
.introduction-title {
  font-size: 40px;
 
  font-weight: bold;
  opacity: 0;
  animation: content-appear 0.2s ease-in forwards;
  animation-delay: 0.6s;
}
.introduction-content {
  position: relative;
  left: 10px;

  font-size: 25px;
  opacity: 0;
  animation: content-appear 0.4s ease-in forwards;
  animation-delay: 0.8s;
}

@keyframes introduction-appear {
  from {
    background-color: transparent;
    height: 400px;
    width: 0px;
  }
  to {
    height: 400px;
    width: 600px;
  }
}
@keyframes content-appear {
  from {
    transform: translateY(-100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
} 

/*--------Projects-----------*/


/*--------Painting-----------*/
#img4 .content {
  position: relative;
  top: 20%;
  left: 10%;
  height: 100px;
  width: 200px;
  border: solid 5px red;
  color: red;
  background-color: rgba(255, 77, 103, 0.409);
}

.painting-book {
  position: relative;
  left: 700px;
  border: solid red 2px;
  height: 500px;
  width: 400px;
  transform-style: preserve-3d;
  perspective: 900px;
  list-style: none;
}

.painting-book li {
  position: absolute;
  transform-origin: left;
  cursor: grab;
}

.painting-book li:nth-child(1) {
  transform: rotateY(-25deg);
  transition: 2s;
}
.painting-book li:nth-child(2) {
  transform: rotateY(-19deg);
  transition: 1.7s;
}
.painting-book li:nth-child(3) {
  transform: rotateY(-17deg);
  transition: 1.4s;
}
.painting-book li:nth-child(4) {
  transform: rotateY(-15deg);
  transition: 1.1s;
}
.painting-book li:nth-child(5) {
  transform: rotateY(-13deg);
  transition: 0.8s;
}

li img {
  height: 350px;
  width: 250px;
}

/*--------Painting-----------*/
