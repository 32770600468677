/*--------Photographs-----------*/
:root {
  /* --albums-height: 200px;*/
  --albums-height: calc(10vh * 4);
}

#img2 {
  padding: 0;
}
#img2 .img-title {
  font-size: 5rem;
  font-weight: bolder;
  color: rgb(255, 255, 255);
  /* border: solid 3px black; */
  width: auto;
  height: auto;
  text-shadow: -6px 2px 0 #7a7a7af2, -40px -5px 10px rgba(0, 0, 0, 0);
  margin: 0;
  position: absolute;
  left: 0;
  top: 0;
}

#img2 .main-content {
  /* border: solid 3px black; */
  height: calc(var(--albums-height) + 1.5rem);
  width: calc(1 * var(--albums-height) * 0.75);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  /* bottom: calc(310vh); */
  left: 50%;
  top: 50%;
  transform: translateX(calc(-1.8 * var(--albums-height) * 0.75))
    translateY(calc(-0.2 * var(--albums-height)));
}

.albums {
  height: var(--albums-height);
  width: calc(var(--albums-height) * 0.75);
  border: solid 0px transparent;
  margin: 0 calc(var(--albums-height) * 0.1);
  border-radius: calc(var(--albums-height) * 0.1);
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px,
    rgba(0, 0, 0, 0.7) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  cursor: pointer;
}
.albums-collection {
  /* border: solid 1px black; */
  transition: 1s ease-in-out;
}

.albums-collection:hover {
  transform: scale(1.12);
  margin: 0 calc(var(--albums-height) * 0.2);
  transition: 0.6s ease-in-out;
}
.albums-collection:not(:hover) {
  transition: 0.6s ease-in-out;
}

.albums-collection p {
  /* border: solid 3px pink; */
  height: 1.5rem;
  margin: 0;
  font-size: 1.5rem;
  color: white;
  text-shadow: 3px 3px 3px rgb(40, 40, 40);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

#landscape {
  background: url("../../../public/landscape-album.jpg");
}

#pet {
  background: url(../../../public/pets-album.JPG);
}

#portrait {
  background: url("../../../public/portrait-album.JPG");
}
#img2 .albums {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

/*--------Photographs-----------*/

@keyframes hidden-albums {
  from {
    opacity: 1;

  }
  to {
    opacity: 0;
  }
}

/* @keyframes show-albums {
  from {
    opacity: 0;

  }
  to {
    opacity: 1;
  }
} */

@keyframes close-collections {
  from {
    left:50%;
    transform: translateX(calc(-1.8 * var(--albums-height) * 0.75))
    translateY(calc(-0.2 * var(--albums-height))) scale(1);
    top:50%;
  }
  to {
    left:var(--collections-left);
    transform:scale(0.8);
    top:calc(100vh - var(--albums-height) - 1.5rem);
  }
}